.boton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--btn);
  color: #fff;
  font-weight: 500;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
  transition: 0.5s ease all;
  position: relative;
  overflow: hidden;
}

.boton span {
  position: relative;
  z-index: 2;
  transition: 0.3s ease all;
}

/* primer estilo */

.boton.one::after {
  content: "";
  width: 100%;
  height: 300px;
  background: var(--btn-hover);
  position: absolute;
  z-index: 1;
  top: -300px;
  left: 0;
  transition: 0.6s ease-in-out all;
  border-radius: 0px 0px 300px 300px;
}

.boton.one:hover::after {
  top: 0;
}

/* segundo estilo */

.boton.two::after {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--btn-hover);
  position: absolute;
  z-index: 1;
  top: -80px;
  left: 0;
  transition: 0.6s ease-in-out all;
}

.boton.two:hover::after {
  top: 0;
}

/* tercer estilo */

.boton.three::after {
  content: "";
  width: 1px;
  height: 1px;
  background: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transition: 0.9s ease-in-out all;
  border-radius: 100px;
  transform-origin: center;
}

.boton.three:hover::after {
  transform: scale(400);
  background: var(--btn-hover);
}

/*boton del formulario */

.formContent__btn {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: var(--btn) !important;
  color: #fff !important;
  font-weight: 500 !important;
  border: none !important;
  cursor: pointer !important;
  text-transform: capitalize !important;
  transition: 0.5s ease all !important;
  position: relative !important;
  overflow: hidden !important;
  width: auto !important;
  padding: 16px 24px !important;
  display: flex !important;
  justify-content: flex-start !important;
}

.boton.five {
  --border: 5px; /* the border width */
  --slant: 0.7em; /* control the slanted corners */
  --color: #fff; /* the color */

  font-size: 22px;
  padding: 0.4em 1.2em;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: var(--color);
  background: linear-gradient(to bottom left, var(--color) 50%, #0000 50.1%) top
      right,
    linear-gradient(to top right, var(--color) 50%, #0000 50.1%) bottom left;
  background-size: calc(var(--slant) + 1.3 * var(--border))
    calc(var(--slant) + 1.3 * var(--border));
  background-repeat: no-repeat;
  box-shadow: 0 0 0 200px inset var(--s, #0000),
    0 0 0 var(--border) inset var(--color);
  clip-path: polygon(
    0 0,
    calc(100% - var(--slant)) 0,
    100% var(--slant),
    100% 100%,
    var(--slant) 100%,
    0 calc(100% - var(--slant))
  );
  transition: color var(--t, 0.3s), background-size 0.3s;
}
.boton.five:focus-visible {
  outline-offset: calc(-1 * var(--border));
  outline: var(--border) solid #000c;
  clip-path: none;
  background-size: 0 0;
}
.boton.five:hover,
.boton.five:active {
  background-size: 100% 100%;
  color: #000;
  --t: 0.2s 0.1s;
}
.boton.five:active {
  --s: #0005;
  transition: none;
}

/* sexto estilo */
.boton.six {
  width: 10em;
  position: relative;
  height: 3.5em;
  border: 3px ridge #7a2121;
  /* outline: none; */
  background-color: transparent;
  color: rgb(255, 255, 255);
  transition: 1s;
  border-radius: 0.3em;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.boton.six::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 3%;
  width: 95%;
  height: 40%;
  background-color: #9b2a2a;
  transition: 0.5s;
  transform-origin: center;
}

.boton.six::before {
  content: "";
  transform-origin: center;
  position: absolute;
  top: 80%;
  left: 3%;
  width: 95%;
  height: 40%;
  background-color: #9b2a2a;
  transition: 0.5s;
}

.boton.six:hover::before, .boton.six:hover::after {
  transform: scale(0)
}

.boton.six:hover {
  box-shadow: inset 0px 0px 25px #9b2a2a;
}
